import React, { useContext, useState, useCallback, useEffect } from "react";
import { UserContext } from "..";
import { fetch_with_token, useUpdateBalance } from "../api";
import NormalSlider from "../components/dice/normalSlider";
import SliderReverse from "../components/dice/normalSliderReverse";
import Range from "../components/dice/range";
import RangeReverse from "../components/dice/rangeReverse";
import MultiRange from "../components/dice/multiRange";

const betModify = new Audio(require("../assets/audio/bet_modify.mp3"));
const classNames = require("classnames");

const App = () => {
  const { user } = useContext(UserContext);

  const [values, setValues] = useState();
  const [mulitplier, setMultiplier] = useState();
  const [winAmount, setWinAmount] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [gameMode, setGameMode] = useState(0);
  const [slider, setSlider] = useState();
  const [lastWin, setLastWin] = useState("");
  const [lastWinNumber, setLastWinNumber] = useState(-1);
  let current_timeout = null;

  /**
   * Handles the range change
   */
  const handleRangeChange = useCallback(
    (values, multiplier) => {
      setValues(values);
      setMultiplier(multiplier);
      setWinAmount((betAmount * multiplier).toFixed(2));
    },
    [betAmount]
  );

  const updateBalance = useUpdateBalance();

  /**
  * Handles the half bet
  */
  const halfBet = useCallback(() => {
    setBetAmount((prev) =>
      parseFloat(Math.round((prev / 2) * 100) / 100).toFixed(2)
    );
  }, []);

  /**
  * Handles the double bet
  */
  const doubleBet = useCallback(() => {
    setBetAmount((prev) => parseFloat(prev * 2).toFixed(2));
  }, []);

  /**
  * Handles the max bet
  */
  const maxBet = useCallback(() => {
    setBetAmount(parseFloat(user.balance).toFixed(2));
  }, [user.balance]);

  /**
  * Starts the game
  */
  function startGameCallback() {
    clearTimeout(current_timeout);
    setLastWin("");
    fetch_with_token("POST", "/api/dice/roll", {
      betAmount,
      gameMode,
      values,
    }).then(async (res) => {
      if (res.ok) {
        const data = await res.json();
        console.log(data);
        setLastWinNumber(data.rolledDice);
        await updateBalance();
        if (!data.won) return;
        current_timeout = setTimeout(() => {
          setLastWin(data.payout + "-" + data.multiplier);
        }, 100);
      }
    });
  }

  /**
  * Last win number component
  */
  function lastWinNumberComponent(lastWinNumber) {
    return (
      <div
        className="w-full h-10 absolute flex justify-end items-center transition-all duration-300"
        style={{ width: lastWinNumber + "%" }}
      >
        <div className="flex items-center justify-center w-10 h-10 relative">
          <svg
            className="w-10 absolute"
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M406.069 52.9658H105.931L0 158.897L256 459.034L512 158.897L406.069 52.9658Z"
              fill="#00C3FF"
            />
            <path
              d="M406.069 52.9658L256 459.034L512 158.897L406.069 52.9658Z"
              fill="#87DAFF"
            />
            <path
              d="M105.931 52.9658L145.084 158.897H0L105.931 52.9658Z"
              fill="#00AAF0"
            />
            <path
              d="M256 52.9658L145.084 158.897H366.916L256 52.9658Z"
              fill="#87DAFF"
            />
            <path
              d="M366.916 158.897H512L406.069 52.9658L366.916 158.897Z"
              fill="#A5E9FF"
            />
            <path
              d="M0 158.897H145.084L256 459.034L0 158.897Z"
              fill="#0096DC"
            />
          </svg>
          <p className="z-[1] font-bold text-black mb-2">{lastWinNumber}</p>
        </div>
      </div>
    );
  }

  /**
   * Switches the slider
   */
  const switchSlider = useCallback(() => {
    switch (gameMode) {
      case 0:
        return (
          <NormalSlider onRangeChange={handleRangeChange}>
            {lastWinNumber !== -1 ? (
              lastWinNumberComponent(lastWinNumber)
            ) : (
              <></>
            )}
          </NormalSlider>
        );
      case 1:
        return (
          <SliderReverse onRangeChange={handleRangeChange}>
            {lastWinNumber !== -1 ? (
              lastWinNumberComponent(lastWinNumber)
            ) : (
              <></>
            )}
          </SliderReverse>
        );
      case 2:
        return (
          <Range onRangeChange={handleRangeChange}>
            {lastWinNumber !== -1 ? (
              lastWinNumberComponent(lastWinNumber)
            ) : (
              <></>
            )}
          </Range>
        );
      case 3:
        return (
          <RangeReverse onRangeChange={handleRangeChange}>
            {lastWinNumber !== -1 ? (
              lastWinNumberComponent(lastWinNumber)
            ) : (
              <></>
            )}
          </RangeReverse>
        );
      default:
        return (
          <MultiRange onRangeChange={handleRangeChange}>
            {lastWinNumber !== -1 ? (
              lastWinNumberComponent(lastWinNumber)
            ) : (
              <></>
            )}
          </MultiRange>
        );
    }
  }, [gameMode, lastWinNumber, handleRangeChange]);


  /**
  * Switch slider on game mode change
  */
  useEffect(() => {
    setSlider(switchSlider());
  }, [gameMode, lastWinNumber, switchSlider]);

  return (
    <div className="flex lg:flex-row flex-col justify-around gap-10 w-full bg-primary rounded-lg lg:h-full">
      <div className="flex flex-col gap-5 w-full lg:w-96 bg-primary p-3 rounded-md h-full">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-bold">Bet Amount</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className="text-lg font-bold text-text-secondary">$</p>
            <input
              value={betAmount}
              type="number"
              className="w-full flex-1 h-12 bg-transparent outline-none"
              onChange={(e) => {
                setBetAmount(Number(e.target.value));
                console.log(e.target.value, mulitplier);
                setWinAmount((e.target.value * mulitplier).toFixed(2));
              }}
            />
            <div className="flex items-center gap-1">
              <button
                onClick={halfBet}
                className="bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2 rounded-l-lg"
              >
                1/2
              </button>
              <button
                onClick={doubleBet}
                className="bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2"
              >
                2x
              </button>
              <button
                onClick={maxBet}
                className="bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2 rounded-r-lg"
              >
                Max
              </button>
            </div>
          </div>
          <p className="text-lg font-bold">Payout on Win</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className="text-lg font-bold text-text-secondary">$</p>
            <input
              value={winAmount}
              type="number"
              className="w-full flex-1 h-12 bg-transparent outline-none"
              readOnly
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-lg font-bold">Game Mode - Role Between</p>
          <div className="flex items-center gap-1 text-center font-bold text-sm bg-background rounded-lg h-12 p-1 text-text-secondary">
            <button
              onClick={() => {
                betModify.play();
                setGameMode(0);
              }}
              className={classNames(
                "flex items-center justify-center w-1/5 rounded-l-md p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                gameMode === 0
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="7"
                  y1="6"
                  y2="6"
                  stroke="currentColor"
                  strokeWidth="2"
                ></line>
                <circle cx="12" cy="6" r="6" fill="currentColor"></circle>
              </svg>
            </button>
            <button
              onClick={() => {
                betModify.play();
                setGameMode(1);
              }}
              className={classNames(
                "flex items-center justify-center w-1/5 p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                gameMode === 1
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="-1"
                  x2="7"
                  y2="-1"
                  transform="matrix(1 0 0 -1 11 5)"
                  stroke="currentColor"
                  strokeWidth="2"
                ></line>
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  transform="matrix(-1 0 0 1 12 0)"
                  fill="currentColor"
                ></circle>
              </svg>
            </button>
            <button
              onClick={() => {
                betModify.play();
                setGameMode(2);
              }}
              className={classNames(
                "flex items-center justify-center w-1/5 p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                gameMode === 2
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              <svg
                width="29"
                height="12"
                viewBox="0 0 29 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="-1"
                  x2="7"
                  y2="-1"
                  transform="matrix(1 0 0 -1 11 5)"
                  stroke="currentColor"
                  strokeWidth="2"
                ></line>
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  transform="matrix(-1 0 0 1 12 0)"
                  fill="currentColor"
                ></circle>
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  transform="matrix(-1 0 0 1 29 0)"
                  fill="currentColor"
                ></circle>
              </svg>
            </button>
            <button
              onClick={() => {
                betModify.play();
                setGameMode(3);
              }}
              className={classNames(
                "flex items-center justify-center w-1/5 p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                gameMode === 3
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              <svg
                width="39"
                height="12"
                viewBox="0 0 39 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="-1"
                  x2="7"
                  y2="-1"
                  transform="matrix(1 0 0 -1 0 5)"
                  stroke="currentColor"
                  strokeWidth="2"
                ></line>
                <line
                  y1="-1"
                  x2="7"
                  y2="-1"
                  transform="matrix(1 0 0 -1 32 5)"
                  stroke="currentColor"
                  strokeWidth="2"
                ></line>
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  transform="matrix(-1 0 0 1 33 0)"
                  fill="currentColor"
                ></circle>
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  transform="matrix(-1 0 0 1 18 0)"
                  fill="currentColor"
                ></circle>
              </svg>
            </button>
            <button
              onClick={() => {
                betModify.play();
                setGameMode(4);
              }}
              className={classNames(
                "flex items-center justify-center w-1/5 rounded-r-md p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                gameMode === 4
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              <svg
                width="63"
                height="12"
                viewBox="0 0 63 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="-1"
                  x2="7"
                  y2="-1"
                  transform="matrix(1 0 0 -1 11 5)"
                  stroke="currentColor"
                  strokeWidth="2"
                ></line>
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  transform="matrix(-1 0 0 1 12 0)"
                  fill="currentColor"
                ></circle>
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  transform="matrix(-1 0 0 1 29 0)"
                  fill="currentColor"
                ></circle>
                <line
                  y1="-1"
                  x2="7"
                  y2="-1"
                  transform="matrix(1 0 0 -1 45 5)"
                  stroke="currentColor"
                  strokeWidth="2"
                ></line>
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  transform="matrix(-1 0 0 1 46 0)"
                  fill="currentColor"
                ></circle>
                <circle
                  cx="6"
                  cy="6"
                  r="6"
                  transform="matrix(-1 0 0 1 63 0)"
                  fill="currentColor"
                ></circle>
              </svg>
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <button
            onClick={() => {
              betModify.play();
              startGameCallback();
            }}
            className="rounded-md bg-gradient-to-tr from-accent-light from-[45%] to-[55%] to-accent-dark text-white font-bold w-full h-10"
          >
            <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Roll Dice
            </p>
          </button>
        </div>
      </div>
      <div className="w-full lg:w-2/5 bg-background rounded-lg p-3 lg:m-3 flex justify-center gap-2 relative lg:h-[90%]">
        {lastWin !== "" && lastWin !== "-1" ? (
          <div className="absolute w-full h-full flex items-center justify-center z-10">
            <div className="w-40 h-32 rounded-xl bg-primary flex items-center flex-col justify-center border-4 font-bold text-xl text-win border-win">
              <p className="text-3xl"> {lastWin.split("-")[1]}x</p>
              <hr className="w-1/2 my-3 bg-win border-win" />
              <p>$ {parseFloat(lastWin.split("-")[0]).toFixed(2)}</p>
            </div>
          </div>
        ) : (
          <></>
        )}

        {slider}
      </div>
    </div>
  );
};

export default App;
