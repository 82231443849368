import { UserContext } from "../index.js";
import Logo from "../assets/logo.png";
import { useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useUpdateBalance } from "../api.js";

function Navbar() {
  const [logged_in, setLogged_in] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const updateBalance = useUpdateBalance();

  useEffect(() => {
    const token = localStorage.getItem("token") || "";
    if (token) {
      try {
        const local_user = jwtDecode(token);
        if (!local_user) {
          localStorage.removeItem("token");
          setLogged_in(false);
        } else {
          setUser(local_user);
          setLogged_in(true);
          updateBalance();
        }
      } catch (error) {
        localStorage.removeItem("token");
        setLogged_in(false);
      }
    }
    // eslint-disable-next-line
  }, [setUser]);

  const handleLogin = () => {
    window.location.href = "/login";
  };

  const handleRegister = () => {
    window.location.href = "/register";
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(undefined);
    window.location.href = "/";
  };

  function formatBalance(balance) {
    const num = parseFloat(balance);
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num.toFixed(2);
    }
  }

  return (
    <nav className="w-full flex items-center justify-around h-24 bg-primary text-accent-dark">
      <a
        aria-label="Go Home"
        href="/"
        className="flex items-center gap-3 text-2xl font-bold"
      >
        <img className="w-24 h-24 min-w-24 min-h-24" src={Logo} alt="" />
      </a>

      {logged_in ? (
        <div className="flex items-center gap-10">
          <div className="bg-background rounded-lg flex items-center gap-2 p-1">
            <div className="flex items-center gap-1 text-xl font-bold bg-primary p-2">
              <svg
                className="w-5"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M256 484C381.921 484 484 381.921 484 256C484 130.079 381.921 28 256 28C130.079 28 28 130.079 28 256C28 381.921 130.079 484 256 484Z"
                  fill="#FEDA2C"
                />
                <path
                  d="M256 431.76C353.07 431.76 431.76 353.07 431.76 256C431.76 158.93 353.07 80.24 256 80.24C158.93 80.24 80.24 158.93 80.24 256C80.24 353.07 158.93 431.76 256 431.76Z"
                  fill="#FCAA17"
                />
                <path
                  d="M256 120.928L295.972 201.92L385.348 214.908L320.676 277.952L335.944 366.968L256 324.94L176.056 366.968L191.324 277.952L126.648 214.908L216.028 201.92L256 120.928Z"
                  fill="#FEDA2C"
                />
              </svg>
              <p>{formatBalance(parseFloat(user.balance).toFixed(2))}</p>
            </div>

            <svg
              onClick={logout}
              className="w-5 fill-text-secondary cursor-pointer hover:fill-white transition-all duration-300 ease-in-out"
              viewBox="0 0 135 135"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_18_2)">
                <path
                  d="M55.5034 114.094C55.3318 113.638 55.2727 113.249 55.3309 112.935C55.3888 112.623 55.1893 112.365 54.7318 112.165C54.2743 111.966 54.046 111.808 54.046 111.695C54.046 111.581 53.7181 111.495 53.0608 111.439C52.4038 111.382 52.0759 111.352 52.0759 111.352H50.9629H50.0209H25.3531C21.5845 111.352 18.358 110.012 15.6739 107.326C12.9904 104.642 11.6488 101.417 11.6488 97.6487V37.3493C11.6488 33.5807 12.9901 30.3554 15.6739 27.6716C18.358 24.9875 21.5845 23.645 25.3531 23.645H52.7614C53.5039 23.645 54.0877 23.4608 54.517 23.0888C54.9445 22.7177 55.2295 22.1606 55.3729 21.4184C55.5157 20.6762 55.6018 20.006 55.6306 19.4063C55.6588 18.8072 55.645 18.05 55.5874 17.1365C55.5307 16.2233 55.5022 15.6524 55.5022 15.4229C55.5022 14.6807 55.2307 14.0396 54.6886 13.496C54.1459 12.9548 53.5039 12.6833 52.7617 12.6833H25.3531C18.5587 12.6833 12.7483 15.0959 7.92281 19.9208C3.09851 24.7457 0.685905 30.5552 0.685905 37.3502V97.6484C0.685905 104.443 3.09851 110.254 7.92281 115.077C12.7483 119.904 18.5587 122.317 25.3531 122.317H52.7626C53.5051 122.317 54.0892 122.13 54.5182 121.761C54.9457 121.389 55.2307 120.832 55.3741 120.089C55.5169 119.348 55.603 118.675 55.6318 118.077C55.66 117.478 55.6462 116.722 55.5886 115.808C55.5313 114.894 55.5034 114.323 55.5034 114.094Z"
                  fill="inherit"
                />
                <path
                  d="M133.361 63.6455L86.7664 17.0498C85.6822 15.9662 84.397 15.4226 82.9123 15.4226C81.4276 15.4226 80.1424 15.9662 79.057 17.0498C77.9719 18.1349 77.4298 19.421 77.4298 20.9048V45.5726H39.058C37.5724 45.5726 36.2881 46.1162 35.203 47.1998C34.1179 48.2849 33.5758 49.5701 33.5758 51.0548V83.9456C33.5758 85.43 34.1179 86.7152 35.203 87.7997C36.2893 88.8827 37.5733 89.4278 39.058 89.4278H77.4301V114.095C77.4301 115.579 77.9722 116.865 79.0573 117.949C80.1424 119.035 81.4276 119.578 82.9126 119.578C84.397 119.578 85.6822 119.035 86.7667 117.949L133.361 71.3558C134.446 70.2698 134.988 68.9864 134.988 67.5014C134.988 66.017 134.446 64.7306 133.361 63.6455Z"
                  fill="inherit"
                />
              </g>
              <defs>
                <clipPath id="clip0_18_2">
                  <rect
                    width="134.302"
                    height="134.302"
                    fill="inherit"
                    transform="translate(0.685905 0.348877)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      ) : (
        <div className="bg-background rounded-lg flex items-center gap-2 p-1">
          <button
            className="rounded-md text-white bg-primary w-20 h-10 font-bold"
            onClick={handleLogin}
          >
            Login
          </button>
          <button
            className="rounded-md bg-gradient-to-tr from-accent-light from-40% to-60% to-accent-dark text-white font-bold w-24 h-10"
            onClick={handleRegister}
          >
            <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Register
            </p>
          </button>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
