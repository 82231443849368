import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
const betModify = new Audio(require("../../assets/audio/bet_modify.mp3"));

const SliderReverse = ({ onRangeChange, children }) => {
  const initialValue = [50];
  const [value, setValue] = useState(initialValue);
  const [winChance, setWinChance] = useState(50);
  const [mulitplier, setMultiplier] = useState(2.0);

  useEffect(() => {
    onRangeChange(value, mulitplier);
  });

  const handleSliderChange = (newValues) => {
    let valueInRange = newValues;
    if (valueInRange <= 1) {
      valueInRange = 1;
    }
    betModify.play();
    setValue([valueInRange]);
    setWinChance(100 - valueInRange);
    setMultiplier(calculateMuliplier(100 - valueInRange));
    onRangeChange([newValues], mulitplier);
  };

  const calculateMuliplier = (winChance) => {
    return (100 / winChance).toFixed(2);
  };

  const handleNumberChange = (e) => {
    betModify.play();
    let valueInRange = e.target.value;
    if (isNaN(valueInRange)) {
      valueInRange = 1;
    }
    if (valueInRange <= 1) {
      valueInRange = 1;
    } else if (valueInRange > 100) {
      valueInRange = 100;
    }

    setValue([valueInRange]);
    setWinChance(100 - valueInRange);
    setMultiplier(calculateMuliplier(100 - valueInRange));
    onRangeChange([valueInRange], mulitplier);
  };

  return (
    <div className="flex flex-col justify-center gap-5 w-full">
      <div className="w-full">
        <div className="flex items-center justify-between text-center mb-1 relative w-full h-fit">
          {children}
          <p className="w-5">0</p>
          <p className="w-5">25</p>
          <p className="w-5">50</p>
          <p className="w-5">75</p>
          <p className="w-5">100</p>
        </div>
        <div className="bg-primary p-2 rounded-full w-full">
          <Slider
            min={0}
            max={100}
            value={value}
            reverse={true}
            onChange={handleSliderChange}
            trackStyle={[
              {
                backgroundColor: "#64e300",
                borderColor: "#090C1D",
                height: 7,
              },
            ]}
            handleStyle={[
              {
                backgroundColor: "#2c2852",
                opacity: 1,
                borderColor: "#090C1D",
                borderWidth: 5,
                padding: 4,
                marginTop: -5,
              },
            ]}
            railStyle={{
              backgroundColor: "#f11f5e",
              borderColor: "#090C1D",
              height: 7,
            }}
          />
        </div>
      </div>

      <div className="flex items-center gap-5 p-5 bg-primary rounded-md w-full">
        <div className="flex-1 w-full min-w-0">
          <p className="text-lg font-bold">Roll Under</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className="text-lg font-bold text-text-secondary">&lt;</p>
            <input
              value={value[0]}
              onChange={handleNumberChange}
              min={0}
              max={100}
              type="number"
              className="w-full flex-1 h-12 bg-transparent outline-none"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-5 p-5 bg-primary rounded-md w-full">
        <div className="flex-1 w-full min-w-0">
          <p className="text-lg font-bold">Win Chance</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className="text-lg font-bold text-text-secondary">%</p>
            <input
              readOnly={true}
              value={winChance}
              type="number"
              className="w-full flex-1 h-12 bg-transparent outline-none"
            />
          </div>
        </div>

        <div className="flex-1 w-full min-w-0">
          <p className="text-lg font-bold">Multiplier</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className="text-lg font-bold text-text-secondary">X</p>
            <input
              readOnly={true}
              value={mulitplier}
              type="number"
              className="w-full flex-1 h-12 bg-transparent outline-none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderReverse;
