import React, { useState, useContext } from "react";
import { UserContext } from "..";
import { fetch_with_token } from "../api";
import Snoopdogg from "../assets/snoopdogg_register.png";

// name, email, passwort, button für löschen, update

const UpdateUser = () => {
  const { user } = useContext(UserContext);
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [deleteText, setDeleteText] = useState("");

  /**
   * Deletes the user account 
   */
  function deleteCallBack() {
    if (deleteText.toLowerCase() !== "confirm") {
      setError("Please type 'Confirm' to delete your account");
      return;
    }
    fetch_with_token("DELETE", "/api/user/delete").then(async (resp) => {
      if (resp.ok) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      } else {
        setError("Couldn't delete account.");
      }
    });
  }

  /**
   * Updates the username of the user
   */
  function updateUsernameCallBack() {
    fetch_with_token("PUT", "/api/user/update/name", { name: newName }).then(
      async (resp) => {
        const data = await resp.json();
        if (resp.ok) {
          localStorage.setItem("token", data.token);
          window.location.reload();
        } else {
          setError(data.message);
        }
      }
    );
  }

  /**
   * Updates the email of the user
   */
  function updateEmailCallBack() {
    fetch_with_token("PUT", "/api/user/update/email", { email: newEmail }).then(
      async (resp) => {
        const data = await resp.json();
        if (resp.ok) {
          localStorage.setItem("token", data.token);
          window.location.reload();
        } else {
          setError(data.message);
        }
      }
    );
  }

  /**
   * Updates the password of the user
   */
  function updatePasswordCallBack() {
    fetch_with_token("PUT", "/api/user/update/password", {
      password: newPassword,
    }).then(async (resp) => {
      const data = await resp.json();
      if (data.status === "ok") {
        window.location.reload();
      } else {
        setError(data.message);
      }
    });
  }

  return (
    <div className="flex w-full h-full gap-10">
      <div className="w-full min-w-0 flex-1 h-full text-white font-bold flex justify-center items-center">
        <div className="flex flex-col justify-center gap-5 w-full">
          <div className="flex flex-col gap-2">
            <h1 className="text-3xl">Update your user details</h1>
            {error && <h1 className="text-xl text-red-500">{error}</h1>}
          </div>
          <div className="flex flex-col gap-2">
            <h2>
              Current username: <span className="ml-1 italic">{user.name}</span>
            </h2>

            <input
              className="rounded-lg bg-primary p-3 text-white"
              type="text"
              placeholder="New Username"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />

            <button
              className="rounded-md bg-gradient-to-tr from-accent-light to-accent-dark text-white font-bold w-full max-w-24 lg:w-96 h-full"
              onClick={updateUsernameCallBack}
            >
              <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                Update
              </p>
            </button>
          </div>
          <div className="flex flex-col gap-2">
            <h2>
              Change your Email:{" "}
              <span className="ml-1 italic">{user.email}</span>
            </h2>
            <input
              className="rounded-lg bg-primary p-3 text-white"
              type="email"
              placeholder="New Email Address"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <button
              className="rounded-md bg-gradient-to-tr from-accent-light to-accent-dark text-white font-bold w-full max-w-24 lg:w-96 h-full"
              onClick={updateEmailCallBack}
            >
              <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                Update
              </p>
            </button>
          </div>

          <div className="flex flex-col gap-2">
            <h2>Set a new Password</h2>
            <input
              className="rounded-lg bg-primary p-3 text-white"
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button
              className="rounded-md bg-gradient-to-tr from-accent-light to-accent-dark text-white font-bold w-full max-w-24 lg:w-96 h-full"
              onClick={updatePasswordCallBack}
            >
              <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                Update
              </p>
            </button>
          </div>

          <div className="flex flex-col gap-2">
            <h2 className="text-red-500 text-sm font-bold">Danger zone!</h2>
            <h2>Delete your account</h2>
            <input
              className="rounded-lg bg-primary p-3 text-white"
              type="text"
              placeholder="Type: Confirm"
              value={deleteText}
              onChange={(e) => setDeleteText(e.target.value)}
            />
            <button
              className="rounded-md bg-gradient-to-tr from-red-500 to-red-800 text-white font-bold w-full max-w-24 lg:w-96 h-full"
              onClick={deleteCallBack}
            >
              <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                Delete
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex-1 min-w-0 lg:flex justify-end h-full hidden">
        <img
          className="w-auto h-full rounded-md object-cover"
          src={Snoopdogg}
          alt="Snoop Dogg"
        />
      </div>
    </div>
  );
};

export default UpdateUser;
