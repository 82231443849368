import React, { useEffect, useCallback, useContext, useState } from "react";
import { UserContext } from "..";
import { fetch_with_token, useUpdateBalance } from "../api";
import Tile from "../components/mines/Tile";

const betModify = new Audio(require("../assets/audio/bet_modify.mp3"));
const classNames = require("classnames");

function Mines() {
  const { user } = useContext(UserContext);

  const [betAmount, setBetAmount] = useState(10);
  const [lastWin, setLastWin] = useState("");
  const [field, setField] = useState(
    Array.from({ length: 8 }, () => Array(8).fill(0))
  );
  const [fieldVal, setFieldVal] = useState(
    Array.from({ length: 8 }, () => Array(8).fill(0))
  );
  const [revealed, setRevealed] = useState([]);
  const [pressed, setPressed] = useState(1);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(false);
  const [hardnessLevel, setHardnessLevel] = useState(1);

  const updateBalance = useUpdateBalance();

  /**
  * Handles the half bet
  */
  const halfBet = useCallback(() => {
    setBetAmount((prev) =>
      parseFloat(Math.round((prev / 2) * 100) / 100).toFixed(2)
    );
  }, []);

  /**
  * Handles the double bet
  */
  const doubleBet = useCallback(() => {
    setBetAmount((prev) => parseFloat(prev * 2).toFixed(2));
  }, []);

  /**
  * Handles the max bet
  */
  const maxBet = useCallback(() => {
    setBetAmount(parseFloat(user.balance).toFixed(2));
  }, [user.balance]);

  /**
   * Starts the game
   */
  const startGameCallback = () => {
    fetch_with_token("POST", "/api/mines/play", {
      betAmount,
      hardnessLevel,
    }).then(async (res) => {
      if (res.ok) {
        setCurrentlyPlaying(true);
        setLastWin("");
        setField(Array.from({ length: 8 }, () => Array(8).fill(0)));
        setFieldVal(Array.from({ length: 8 }, () => Array(8).fill(0)));
        setRevealed([]);
        setPressed(1);
      }

      await updateBalance();
    });
  };

  /**
   * Handles the tile click event 
   */
  const clickedTileCallback = useCallback(
    (tileID) => {
      const [row, col] = tileID.split("").map(Number);
      fetch_with_token("POST", "/api/mines/pick", { row, col }).then(
        async (res) => {
          if (res.ok) {
            let data = await res.json();

            if (data.win) {
              setLastWin(data.winnings);
              setField(data.field);
              setFieldVal(data.field);
              setRevealed(
                data.field.flatMap((row, i) =>
                  row.map((cell, j) => ({ row: i, col: j }))
                )
              );
              setCurrentlyPlaying(false);
              setPressed(1);
            } else if (data.advance) {
              setPressed((prev) => prev + 1);
              setFieldVal((prev) => {
                const newFieldVal = [...prev];
                newFieldVal[row][col] = data.val;
                return newFieldVal;
              });
              setRevealed((prev) => [...prev, { row, col }]);
            } else {
              setField(data.field);
              setFieldVal(data.field);
              setRevealed(
                data.field.flatMap((row, i) =>
                  row.map((cell, j) => ({ row: i, col: j }))
                )
              );
              setLastWin("-1");
              setCurrentlyPlaying(false);
              setPressed(1);
            }
          }
        }
      );
    },
    [
      setLastWin,
      setField,
      setFieldVal,
      setRevealed,
      setCurrentlyPlaying,
      setPressed,
    ]
  );

  /**
   * Generates the field on hardness level change
   */
  const generateField = useCallback(() => {
    let newField = [];
    for (let i = 0; i < 8; i++) {
      let children = [];
      for (let j = 0; j < 8; j++) {
        children.push(
          <Tile
            key={`${i}${j}`}
            id={`${i}${j}`}
            row={i}
            col={j}
            callback={clickedTileCallback}
            revealed={revealed}
            val={fieldVal[i][j]}
            hard={hardnessLevel}
            pressedCount={pressed}
          />
        );
      }
      newField.push(
        <div key={i} className="flex flex-col gap-2 w-full">
          {children}
        </div>
      );
    }
    return newField;
  }, [clickedTileCallback, revealed, fieldVal, hardnessLevel, pressed]);

  /**
   * Generates the field on hardness level change
   */
  useEffect(() => {
    setField(generateField());
  }, [revealed, fieldVal, setHardnessLevel, hardnessLevel, generateField]);

  /**
   * Cash out the game
   */
  function cashOutCallback() {
    fetch_with_token("POST", "/api/mines/cashout").then(async (res) => {
      if (res.ok) {
        // console.log(1);
        let data = await res.json();

        setLastWin(data.winnings);
        setField(data.field);
        setFieldVal(data.field);
        setRevealed(
          data.field.flatMap((row, i) =>
            row.map((cell, j) => ({ row: i, col: j }))
          )
        );
        setCurrentlyPlaying(false);
        setPressed(1);
      }

      await updateBalance();
    });
  }

  /**
   * Resets the game on hardness level change
   */
  useEffect(() => {
    setField(Array.from({ length: 8 }, () => Array(8).fill(0)));
    setFieldVal(Array.from({ length: 8 }, () => Array(8).fill(0)));
    setRevealed([]);
    setPressed(1);
    setLastWin("");
    setCurrentlyPlaying(false);
  }, [hardnessLevel]);

  return (
    <div className="flex xl:flex-row items-center flex-col justify-around gap-10 w-full bg-primary rounded-lg lg:h-full">
      <div className="flex flex-col gap-5 w-full xl:w-[50rem] bg-primary p-3 rounded-md h-full">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-bold">Bet Amount</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className="text-lg font-bold text-text-secondary">$</p>
            <input
              value={betAmount}
              type="number"
              className="w-full flex-1 h-12 bg-transparent outline-none"
              onChange={(e) => setBetAmount(Number(e.target.value))}
            />
            <div className="flex items-center gap-1">
              <button
                onClick={halfBet}
                className="bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2 rounded-l-lg"
              >
                1/2
              </button>
              <button
                onClick={doubleBet}
                className="bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2"
              >
                2x
              </button>
              <button
                onClick={maxBet}
                className="bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2 rounded-r-lg"
              >
                Max
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-lg font-bold">Toughness Level</p>
          <div className="flex items-center gap-1 text-center font-bold text-sm bg-background rounded-lg h-12 p-1 text-text-secondary">
            <button
              onClick={() => {
                if (currentlyPlaying) return;
                betModify.play();
                setHardnessLevel(0);
              }}
              className={classNames(
                "w-1/5 rounded-l-md p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                hardnessLevel === 0
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              Easy
            </button>
            <button
              onClick={() => {
                if (currentlyPlaying) return;
                betModify.play();
                setHardnessLevel(1);
              }}
              className={classNames(
                "w-1/5 p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                hardnessLevel === 1
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              Medium
            </button>
            <button
              onClick={() => {
                if (currentlyPlaying) return;
                betModify.play();
                setHardnessLevel(2);
              }}
              className={classNames(
                "w-1/5 p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                hardnessLevel === 2
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              {" "}
              Hard
            </button>
            <button
              onClick={() => {
                if (currentlyPlaying) return;
                betModify.play();
                setHardnessLevel(3);
              }}
              className={classNames(
                "w-1/5 p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                hardnessLevel === 3
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              {" "}
              Expert
            </button>
            <button
              onClick={() => {
                if (currentlyPlaying) return;
                betModify.play();
                setHardnessLevel(4);
              }}
              className={classNames(
                "w-1/5 rounded-r-md p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                hardnessLevel === 4
                  ? "bg-primary-active text-white"
                  : "hover:bg-primary-active/40 "
              )}
            >
              {" "}
              Crazy
            </button>
          </div>
        </div>
        <div>
          {currentlyPlaying ? (
            <button
              onClick={() => {
                betModify.play();
                cashOutCallback();
              }}
              className="rounded-md bg-gradient-to-tr from-secondary from-[45%] to-[55%] to-secondary-light text-white font-bold w-full h-10"
            >
              <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                Cash out now
              </p>
            </button>
          ) : (
            <button
              onClick={() => {
                betModify.play();
                startGameCallback();
              }}
              className="rounded-md bg-gradient-to-tr from-accent-light from-[45%] to-[55%] to-accent-dark text-white font-bold w-full h-10"
            >
              <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                Start playing
              </p>
            </button>
          )}
        </div>
      </div>
      <div className="w-full bg-background rounded-lg p-3 lg:m-3 flex justify-center gap-2 relative lg:h-[90%]">
        {lastWin !== "" && lastWin !== "-1" ? (
          <div className="absolute w-full h-full flex items-center justify-center z-10">
            <div className="w-40 h-32 rounded-xl bg-primary flex items-center flex-col justify-center border-4 font-bold text-xl text-win border-win">
              <p className="text-3xl"> {lastWin.split("-")[1]}x</p>
              <hr className="w-1/2 my-3 bg-win border-win" />
              <p>$ {parseFloat(lastWin.split("-")[0]).toFixed(2)}</p>
            </div>
          </div>
        ) : (
          <></>
        )}
        {field}
      </div>
    </div>
  );
}

export default Mines;
