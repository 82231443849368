import { useState } from "react";

function AutoLogin() {
    const [image, setImage] = useState(null);

    /**
     * Creates a login QR code
     */
    async function createLogin() {
        const token = localStorage.getItem("token");
        const currentURL = window.location.origin;

        const image = await fetch(`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${currentURL}/login?token=${token}`);
        setImage(image.url);
    }

    return (
        <div className="text-center flex flex-col items-center gap-5">
            <h1 className="text-3xl font-bold">AutoLogin</h1>
            <div className="lg:text-xl">
                <p>Tired of typing in your email and password?</p>
                <p>Click the button below, to generate an Auto-Login QR Code.</p>
            </div>

            <button className="max-w-96 w-full bg-accent-dark px-5 py-3 rounded-lg" onClick={createLogin}>Generate now!<span className="text-xs">*</span></button>
            <div className="flex justify-end">
                <p className="text-xs w-2/3 text-right">*This QR-Code grants access to your whole account. Keep it hidden!</p>
            </div>

            {image ?
                <img className="p-3 bg-white rounded-lg w-[90%] max-w-96" src={image} alt="AutoLogin QR Code" /> :
                <div className="p-3 bg-white rounded-lg w-[90%] aspect-square max-w-96 text-black font-bold flex justify-center items-center text-3xl">
                    <p>Click Generate</p>
                </div>}
        </div>
    );
}

export default AutoLogin;