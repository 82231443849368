import ReactDOM from "react-dom/client";
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Tower from "./pages/Tower";
import Navbar from "./components/Navbar";
import Dice from "./pages/Dice";
import Sidebar from "./components/Sidebar";
import Mines from "./pages/Mines";
import HighLow from "./pages/HighLow";
import AdminPanel from "./pages/AdminPanel";
import UpdateUser from "./pages/UserPorfile";
import "./index.css";
import AutoLogin from "./pages/AutoLogin";

export const UserContext = React.createContext(null);

export default function App() {
  const [user, setUser] = useState({});

  return (
    <UserContext.Provider value={{ user: user, setUser: setUser }}>
      <div className="h-screen relative">
        <Navbar />
        <Sidebar>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/tower" element={<Tower />} />
              <Route path="/dice" element={<Dice />} />
              <Route path="/mines" element={<Mines />} />
              <Route path="/highlow" element={<HighLow />} />
              <Route path="/admin" element={<AdminPanel />} />
              <Route path="/profile" element={<UpdateUser />} />
              <Route path="/autologin" element={<AutoLogin />} />
            </Routes>
          </BrowserRouter>
        </Sidebar>
      </div>
    </UserContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);