import React, { useState, useCallback, useContext } from "react";
import { UserContext } from "..";
import { fetch_with_token, useUpdateBalance } from "../api";

const betModify = new Audio(require("../assets/audio/bet_modify.mp3"));

const Card = ({ card }) => (
    <div className="border-2 border-gray-300 rounded-md p-4 text-center text-2xl">
        <div>{card.value}</div>
        <div>{card.suit}</div>
    </div>
);

function HighLow() {
    const { user } = useContext(UserContext);
    const [betAmount, setBetAmount] = useState(10);
    const [currentCard, setCurrentCard] = useState({ value: `${Math.floor(Math.random() * 10) + 1}`, suit: `${['♥', '♦', '♣', '♠'][Math.floor(Math.random() * 4)]}` });
    const [currentCardValue, setCurrentCardValue] = useState(0);
    const [currentlyPlaying, setCurrentlyPlaying] = useState(false);
    const [gameOver, setGameOver] = useState(false);

    const [HMultiplier, setHMultiplier] = useState(1);
    const [LMultiplier, setLMultiplier] = useState(1);
    const [win, setWin] = useState(0);


    const updateBalance = useUpdateBalance();

    /**
     * Handles the half bet
     */
    const halfBet = useCallback(() => {
        setBetAmount((prev) =>
            parseFloat(Math.round((prev / 2) * 100) / 100).toFixed(2)
        );
    }, []);

    /**
     * Handles the double bet
     */
    const doubleBet = useCallback(() => {
        setBetAmount((prev) => parseFloat(prev * 2).toFixed(2));
    }, []);

    /**
     * Handles the max bet
     */
    const maxBet = useCallback(() => {
        setBetAmount(parseFloat(user.balance).toFixed(2));
    }, [user.balance]);


    /**
     * Starts the game
     */
    const startGameCallback = () => {
        fetch_with_token("POST", "/api/highlow/play", { betAmount }).then(async (res) => {
            if (res.ok) {
                setCurrentlyPlaying(true);
                setGameOver(false);
                console.log("play start");
                let data = await res.json();
                setCurrentCard(data.card);
                setCurrentCardValue(data.card_val);
                console.log(currentCard);
                setHMultiplier(data.mul.hMul);
                setLMultiplier(data.mul.lMul);
                setWin(betAmount);
            }
            else {
                console.error("Failed to start game: ", res);
            }

            await updateBalance();
        }).catch(error => {
            console.log("amk");
            console.error("network or server error:", error);
        });
    };

    /**
     * Handles the guess
     * @param {string} guess
     * @param {number} mul
     */
    const guessCallBack = (guess, mul) => {
        fetch_with_token("POST", "/api/highlow/guess", { currentCardValue, guess, mul, win }).then(async (res) => {

            if (res.ok) {
                let data = await res.json();
                console.log("guessing")
                if (data.win) {
                    setCurrentCard(data.card);
                    setCurrentCardValue(data.card_val);
                    console.log(currentCard);
                    setHMultiplier(data.mul.hMul);
                    setLMultiplier(data.mul.lMul);
                    setWin(data.winning);
                } else {
                    console.log("you got", data.card_val)
                    console.log("You Lost!");
                    setGameOver(true);
                    setCurrentlyPlaying(false);

                }
            }

            await updateBalance();
        });


    };

    /**
     * Cash out
     */
    function cashOutCallback() {
        fetch_with_token("POST", "/api/highlow/cashout", { win }).then(async (res) => {
            if (res.ok) {
                setCurrentlyPlaying(false);
                setCurrentCard({ value: `${Math.floor(Math.random() * 10) + 1}`, suit: `${['♥', '♦', '♣', '♠'][Math.floor(Math.random() * 4)]}` });
                setCurrentCardValue(0);
                setWin(0);
                setHMultiplier(1);
                setLMultiplier(1);
            }

            await updateBalance();
        });
    }

    return (
        <div className="flex lg:flex-row flex-col justify-around gap-10 w-full bg-primary rounded-lg lg:h-full">
            <div className="flex flex-col gap-5 w-full lg:w-96 bg-primary p-3 rounded-md h-full">
                <div className="flex flex-col gap-2">
                    <p className='text-lg font-bold'>Bet Amount</p>
                    <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
                        <p className='text-lg font-bold text-text-secondary'>$</p>
                        <input value={betAmount} type='number' className='w-full flex-1 h-12 bg-transparent outline-none' onChange={
                            (e) => setBetAmount(Number(e.target.value))
                        } />
                        <div className="flex items-center gap-1">
                            <button onClick={halfBet} className='bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2 rounded-l-lg'
                                disabled={currentlyPlaying}>1/2</button>
                            <button onClick={doubleBet} className='bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2'
                                disabled={currentlyPlaying}>2x</button>
                            <button onClick={maxBet} className='bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2 rounded-r-lg'
                                disabled={currentlyPlaying}>Max</button>
                        </div>
                    </div>
                </div>
                <div>
                    {currentlyPlaying ?
                        <button onClick={() => { betModify.play(); cashOutCallback() }}
                            className="rounded-md bg-gradient-to-tr from-secondary from-[45%] to-[55%] to-secondary-light text-white font-bold w-full h-10">

                            <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                                Cash out now
                            </p>
                        </button>
                        :
                        <button onClick={() => { betModify.play(); startGameCallback() }}
                            className="rounded-md bg-gradient-to-tr from-accent-light from-[45%] to-[55%] to-accent-dark text-white font-bold w-full h-10">

                            <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                                Start playing
                            </p>
                        </button>}
                </div>
            </div>


            <div className="flex flex-col items-center gap-10 justify-center bg-primary rounded-lg flex-1 min-w-0">
                <h1 className="text-3xl font-bold mb-8">High-Low Game</h1>
                <Card card={currentCard} />
                <p className="text-xl font-bold mb-8">{gameOver ? <p>Game Over!</p> : <p> Winning: {win}$</p>}</p>
                <div className="flex gap-4 mt-4">
                    <button
                        onClick={() => guessCallBack('higher', HMultiplier)}
                        disabled={gameOver}
                        className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 disabled:bg-gray-300"
                    >
                        Higher {HMultiplier}x
                    </button>
                    <button
                        onClick={() => guessCallBack('lower', LMultiplier)}
                        disabled={gameOver}
                        className="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-600 disabled:bg-gray-300"
                    >
                        Lower {LMultiplier}x
                    </button>
                </div>
            </div>
        </div>

    );
}

export default HighLow;
