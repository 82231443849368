import Bomb from "../../assets/bombs/exploded.png";
import Gold from "../../assets/coins/gold.png";

const classNames = require('classnames');

const scale = {
    0: 1.05,
    1: 1.23,
    2: 1.64,
    3: 3.28,
    4: 4.36
}


function Tile({ id, row, col, callback, revealed, val, hard, pressedCount }) {
    const isRevealed = revealed.some(tile => tile.row === row && tile.col === col);
    const isCurrentRow = parseInt(id.split("")[0]) === row;
    const multiplier = (scale[hard] ** pressedCount).toFixed(2);
    console.log(val)
    return (
        <>
            {isRevealed ?
                <div className='font-bold rounded-md w-full flex-1 min-w-0 min-h-20 lg:min-h-0 h-24 p-3 bg-primary/50 flex justify-center relative items-center'>
                    {val ?
                        <img className='w-10 absolute' src={Bomb} alt="Bomb" /> :
                        <img className='w-10 absolute' src={Gold} alt="Gold" />}
                </div>
                :
                <button
                    onClick={() => callback(id)}
                    className={classNames(
                        "text-xs lg:text-base font-bold rounded-md w-full flex-1 min-w-0 min-h-20 lg:min-h-0 h-24 lg:p-3 bg-primary/50 flex justify-center relative items-center",
                        isCurrentRow ? "bg-primary-active text-white" : "bg-primary text-text-secondary",
                    )}>
                    {multiplier}x
                </button>
            }
        </>
    );
}

export default Tile;