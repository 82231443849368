import { useState } from 'react';
import Snoopdogg from '../assets/snoopdogg_register.png'
const { REACT_APP_BACKEND_URL } = process.env;

function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState("");

  /**
   * Registers the user to the backend
   */
  async function registerUser() {

    /**
     * Handles the registration
     */
    if (name.length < 3) {
      setError("The name must be at least 3 characters long.");
      return false;
    }

    /**
     * Handles the registration
     */
    if (!email.includes("@")) {
      setError("Please enter a valid e-mail address.");
      return false;
    }
    
    /**
     * Handles the registration
     */
    if (password.length < 8) {
      setError("The password must be at least 8 characters long.");
      return false;
    }
    const response = await fetch(`${REACT_APP_BACKEND_URL}/api/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, password }),
    });

    const data = await response.json();

    if (data.status === 'ok') {
      window.location.href = '/login';
    } else {
      setError(data.message);
    }
  }

  return (
    <div className="flex w-full h-full gap-10">
      <div className="w-full min-w-0 flex-1 h-full text-white font-bold flex justify-center items-center">
        <div className="flex flex-col justify-center gap-5 w-full">
          <div className="flex flex-col gap-2">
            <h1 className='text-3xl'>Register</h1>
            <p className="text-lg text-secondary-light">Welcome, please sign up!</p>
          </div>

          <div className="flex flex-col gap-1">
            <h2>Username</h2>
            <input
              onKeyDown={(e) => e.key === 'Enter' && Register()}

              className='rounded-lg bg-primary p-3 text-white lg:w-96' type="text" placeholder="Username" value={name} onChange={(e) => setName(e.target.value)} />
          </div>

          <div className="flex flex-col gap-1">
            <h2>Email Adress</h2>
            <input
              onKeyDown={(e) => e.key === 'Enter' && Register()}
              className='rounded-lg bg-primary p-3 text-white lg:w-96' type="email" placeholder="Email Adress" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div className="flex flex-col gap-1">
            <h2>Password</h2>
            <input
              onKeyDown={(e) => e.key === 'Enter' && Register()}
              className='rounded-lg bg-primary p-3 text-white lg:w-96' type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          {error && <h1 className="text-xl text-red-500">{error}</h1>}
          <button className="rounded-md bg-gradient-to-tr from-accent-light from-[45%] to-[55%] to-accent-dark text-white font-bold w-full lg:w-96 h-14" onClick={registerUser}>
            <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">Register</p>
          </button>

          <p>Already have an account? <a href="/login" className='border-b border-accent-dark text-accent-light'>Login</a></p>
        </div>
      </div>

      <div className="w-full flex-1 min-w-0 lg:flex justify-end h-full hidden">
        <img className="w-auto h-full rounded-md object-cover" src={Snoopdogg} alt="Snoop Dogg" />
      </div>
    </div>
  );
}

export default Register;
