import React, { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "..";
import Tile from "../components/tower/Tile";
import { fetch_with_token, useUpdateBalance } from "../api";
const betModify = new Audio(require("../assets/audio/bet_modify.mp3"));
const classNames = require('classnames');

const column_amount = {
    0: 4,
    1: 3,
    2: 2,
    3: 3,
    4: 4
}

function Tower() {
    const { user } = useContext(UserContext);

    const [betAmount, setBetAmount] = useState(0);
    const [hardnessLevel, setHardnessLevel] = useState(0);
    const [current_row, setCurrent_row] = useState(0);
    const [columns, setColumns] = useState([]);
    const [lastWin, setLastWin] = useState('');

    const [currentlyPlaying, setCurrentlyPlaying] = useState(false);
    const [revealedRows, setRevealedRows] = useState([]);

    const updateBalance = useUpdateBalance();

    /**
    * Handles the half bet
    */
    const halfBet = useCallback(() => {
        setBetAmount((prev) =>
            parseFloat(Math.round((prev / 2) * 100) / 100).toFixed(2)
        );
    }, []);

    /**
    * Handles the double bet
    */
    const doubleBet = useCallback(() => {
        setBetAmount((prev) => parseFloat(prev * 2).toFixed(2));
    }, []);

    /**
    * Handles the max bet
    */
    const maxBet = useCallback(() => {
        setBetAmount(parseFloat(user.balance).toFixed(2));
    }, [user.balance]);
    function startGameCallback() {
        fetch_with_token("POST", "/api/tower/play", { betAmount, hardnessLevel }).then(async (res) => {
            if (res.ok) {
                setRevealedRows([]);
                setCurrentlyPlaying(true);
                setCurrent_row(1);
                setLastWin('');
            }

            await updateBalance();
        });
    }

    /**
     * Cash out the game and get the winnings
     */
    function cashOutCallback() {
        fetch_with_token("POST", "/api/tower/cashout").then(async (res) => {
            if (res.ok) {

                let data = await res.json();

                setLastWin(data.winnings);
                setRevealedRows(data.row);
                setCurrentlyPlaying(false);
            }

            await updateBalance();
        });
    }

    /**
     * Generates the columns for the game
     * @returns {JSX.Element[]}
     */
    const generateColumns = useCallback(() => {
        const clickedTileCallback = (tileID) => {
            fetch_with_token("POST", "/api/tower/pick", { pick: parseInt(tileID.split("")[0]) }).then(async (res) => {
                if (res.ok) {
                    let data = await res.json();

                    if (data.win) {
                        setLastWin(data.winnings);
                        setRevealedRows(data.row);
                        setCurrentlyPlaying(false);
                    }
                    else if (data.advance) {
                        setRevealedRows((prev) => [...prev, data.row]);
                        setCurrent_row((prev) => prev + 1);
                    } else {
                        setRevealedRows(() => data.row)
                        generateColumns();
                        setLastWin('-1');
                        setCurrentlyPlaying(false);
                    }
                }
            })
        };

        let newColumns = [];
        for (let i = 0; i < column_amount[hardnessLevel]; i++) {
            let children = [];
            for (let j = 8; j > 0; j--) {
                children.push(
                    <Tile key={`${i}${j}`} id={`${i}${j}`}
                        row={current_row}
                        hard={hardnessLevel}
                        callback={clickedTileCallback}
                        revealed={revealedRows}
                    />
                );
            }
            newColumns.push(
                <div key={i} className="flex flex-col gap-2 w-full">
                    {children}
                </div>
            );
        }
        return newColumns;
    }, [current_row, hardnessLevel, revealedRows, setLastWin, setCurrentlyPlaying, setRevealedRows, setCurrent_row]);


    /**
     * Generates the columns for the game
     */
    useEffect(() => {
        setColumns(generateColumns());
    }, [current_row, generateColumns, revealedRows]);



    return (
        <div className="flex lg:flex-row flex-col justify-around gap-10 w-full bg-primary rounded-lg lg:h-full">
            <div className="flex flex-col gap-5 w-full lg:w-96 bg-primary p-3 rounded-md h-full">
                <div className="flex flex-col gap-2">
                    <p className='text-lg font-bold'>Bet Amount</p>
                    <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
                        <p className='text-lg font-bold text-text-secondary'>$</p>
                        <input value={betAmount} type='number' className='w-full flex-1 h-12 bg-transparent outline-none' onChange={
                            (e) => {
                                if (currentlyPlaying) return;
                                setBetAmount(Number(e.target.value))
                            }
                        } />
                        <div className="flex items-center gap-1">
                            <button onClick={() => {
                                if (currentlyPlaying) return;
                                halfBet()
                            }} className='bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2 rounded-l-lg'>1/2</button>
                            <button onClick={() => {
                                if (currentlyPlaying) return;
                                doubleBet()
                            }} className='bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2'>2x</button>
                            <button onClick={() => {
                                if (currentlyPlaying) return;
                                maxBet()
                            }} className='bg-primary hover:bg-primary-active transition-all duration-300 hover:text-white
                            text-text-secondary font-bold p-2 rounded-r-lg'>Max</button>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <p className='text-lg font-bold'>Toughness Level</p>
                    <div className="flex items-center gap-1 text-center font-bold text-sm bg-background rounded-lg h-12 p-1 text-text-secondary">
                        <button
                            onClick={() => {
                                if (currentlyPlaying) return;
                                betModify.play();
                                setHardnessLevel(0)
                            }}
                            className={classNames(
                                "w-1/5 rounded-l-md p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                                hardnessLevel === 0 ? "bg-primary-active text-white" : "hover:bg-primary-active/40 "
                            )}>
                            Easy
                        </button>
                        <button
                            onClick={() => {
                                if (currentlyPlaying) return;
                                betModify.play();
                                setHardnessLevel(1)
                            }}

                            className={classNames(
                                "w-1/5 p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                                hardnessLevel === 1 ? "bg-primary-active text-white" : "hover:bg-primary-active/40 "
                            )}>Medium
                        </button>
                        <button
                            onClick={() => {
                                if (currentlyPlaying) return;
                                betModify.play();
                                setHardnessLevel(2)
                            }}
                            className={classNames(
                                "w-1/5 p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                                hardnessLevel === 2 ? "bg-primary-active text-white" : "hover:bg-primary-active/40 "
                            )}>   Hard
                        </button>
                        <button
                            onClick={() => {
                                if (currentlyPlaying) return;
                                betModify.play();
                                setHardnessLevel(3)
                            }}
                            className={classNames(
                                "w-1/5 p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                                hardnessLevel === 3 ? "bg-primary-active text-white" : "hover:bg-primary-active/40 "
                            )}>   Expert
                        </button>
                        <button
                            onClick={() => {
                                if (currentlyPlaying) return;
                                betModify.play();
                                setHardnessLevel(4)
                            }}
                            className={classNames(
                                "w-1/5 rounded-r-md p-2 bg-primary h-full transition-all duration-300 hover:text-white",
                                hardnessLevel === 4 ? "bg-primary-active text-white" : "hover:bg-primary-active/40 "
                            )}>   Crazy
                        </button>
                    </div>
                </div>
                {currentlyPlaying ?
                    <button onClick={() => {
                        betModify.play();
                        cashOutCallback()
                    }}
                        className="rounded-md bg-gradient-to-tr from-secondary from-[45%] to-[55%] to-secondary-light text-white font-bold w-full h-10">

                        <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                            Cash out now
                        </p>
                    </button>
                    :
                    <button onClick={() => {
                        if (currentlyPlaying) return;
                        betModify.play();
                        startGameCallback()
                    }}
                        className="rounded-md bg-gradient-to-tr from-accent-light from-[45%] to-[55%] to-accent-dark text-white font-bold w-full h-10">

                        <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                            Start playing
                        </p>
                    </button>}
            </div>

            <div className="w-full lg:w-2/5 bg-background rounded-lg p-3 lg:m-3 flex justify-center gap-2 relative lg:h-[90%]">
                {lastWin !== '' && lastWin !== '-1' ?
                    <div className="absolute w-full h-full flex items-center justify-center z-10">
                        <div className="w-40 h-32 rounded-xl bg-primary flex items-center flex-col justify-center border-4 font-bold text-xl text-win border-win">
                            <p className="text-3xl"> {lastWin.split('-')[1]}x</p>
                            <hr className="w-1/2 my-3 bg-win border-win" />
                            <p>$ {parseFloat(lastWin.split('-')[0]).toFixed(2)}</p>
                        </div>
                    </div>
                    : <></>
                }

                {columns}
            </div>
        </div>
    );
}

export default Tower;
