import { useCallback, useEffect, useState } from 'react';
import Snoopdogg from "../assets/snoopdogg_register.png";
import { jwtDecode } from 'jwt-decode';
const { REACT_APP_BACKEND_URL } = process.env;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  /**
   * Handles the login
   */
  const login = useCallback(async () => {
    if (!email.includes("@")) {
      setError("Please enter a valid e-mail address.");
      return false;
    }

    if (password.length < 8) {
      setError("The password must be at least 8 characters long.");
      return false;
    }

    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.status === "ok") {
        localStorage.setItem("token", data.token);
        window.location.href = "/";
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("An error occurred during login.");
    }
  }, [email, password]);

  /**
   * Checks if the user has a token in the URL
   */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      try {
        jwtDecode(token);
        window.localStorage.setItem("token", token);
        window.location.href = "/";
      } catch (error) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    }
  }, []);

  return (
    <div className="flex w-full h-full gap-10">
      <div className="w-full min-w-0 flex-1 h-full text-white font-bold flex justify-center items-center">
        <div className="flex flex-col justify-center gap-5 w-full">
          <div className="flex flex-col gap-2">
            <h1 className='text-3xl'>Login</h1>
            <p className="text-lg text-secondary-light">Welcome back! You've been missed!</p>
          </div>
          <div className="flex flex-col gap-2">
            <h2>Email Adress</h2>
            <input
              onKeyDown={(e) => e.key === 'Enter' && login()}
              className='rounded-lg bg-primary p-3 text-white lg:w-96' type="email" placeholder="Email Adress" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="flex flex-col gap-2">
            <h2>Password</h2>
            <input
              onKeyDown={(e) => e.key === 'Enter' && login()}
              className='rounded-lg bg-primary p-3 text-white lg:w-96' type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className="flex flex-col gap-2">
            {error && <h1 className="text-xl text-red-500">{error}</h1>}
            <button className="rounded-md bg-gradient-to-tr from-accent-light from-[45%] to-[55%] to-accent-dark text-white font-bold w-full lg:w-96 h-14" onClick={login}>
              <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">Login</p>
            </button>
          </div>


          <p>Don't have an account? <a href="/register" className='border-b  border-accent-dark text-accent-light'>Register</a></p>
        </div>
      </div>

      <div className="w-full flex-1 min-w-0 lg:flex justify-end h-full hidden">
        <img className="w-auto h-full rounded-md object-cover" src={Snoopdogg} alt="Snoop Dogg" />
      </div>
    </div>
  );
}

export default Login;
