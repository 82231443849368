import { useEffect, useState } from "react";
import { fetch_with_token } from "../api";

function Admin() {
    const [data, setData] = useState({});
    const [email, setEmail] = useState("");
    const [amount, setAmount] = useState(0);

    /**
     * Fetches the data from the API
     */
    function requestAPI() {
        fetch_with_token("GET", "/api/admin/data").then(async (resp) => {
            const data = await resp.json();
            console.log(data);
            if (resp.ok) {
                setData(data);
            } else {
                alert("Couldn't fetch data");
            }
        });
    }

    /**
     * Gives the user a balance
     */
    function giveUserBalance() {
        fetch_with_token("POST", "/api/admin/balance", { mail: email, balance: amount }).then(async (resp) => {
            const data = await resp.json();
            if (resp.ok) {
                requestAPI();
                window.location.reload();
            } else {
                alert(data.message);
            }
        });
    }

    /**
     * Fetches the data from the API on the first load
     */
    useEffect(() => {
        requestAPI();

        let interval = setInterval(requestAPI, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return <div className="w-full flex flex-col items-center">
        <div className="w-full lg:w-1/2 h-full flex flex-col items-center gap-10">
            <div className="text-xl font-bold flex flex-col items-center gap-3 bg-primary rounded-md p-5 w-full">
                <p>User Count</p>
                <p className="font-normal">{data.users}</p>
            </div>
            <div className="text-xl font-bold flex flex-col items-center gap-3 bg-primary rounded-md p-5 w-full">
                <p>Total balance on website</p>
                <p className="font-normal">{data.totalBalance}$</p>
            </div>
            <div className="text-xl font-bold flex flex-col items-center gap-3 bg-primary rounded-md p-5 w-full">
                <p>Open Games:</p>
                <p className="font-normal">Tower: {data.activeGames?.tower}</p>
                <p className="font-normal">Mines: {data.activeGames?.mines}</p>
            </div>
            <div className="flex flex-col gap-5 bg-primary rounded-md p-5 w-full mb-5">
                <p className="text-xl font-bold">Change someone's balance</p>
                <div className="flex flex-col lg:flex-row gap-3">
                    <div className="flex flex-col gap-2 w-full flex-1 min-w-0">
                        <h2>Email Adress</h2>
                        <input
                            onKeyDown={(e) => e.key === 'Enter' && giveUserBalance()}
                            className='rounded-lg bg-background p-3 text-white w-full' type="email" placeholder="Email Adress" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="flex flex-col gap-2 w-full flex-1 min-w-0">
                        <h2>Amount</h2>
                        <input
                            onKeyDown={(e) => e.key === 'Enter' && giveUserBalance()}
                            className='rounded-lg bg-background p-3 text-white w-full' type="number" min={0} value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                </div>
                <button className="rounded-md bg-gradient-to-tr from-accent-light from-[45%] to-[55%] to-accent-dark text-white font-bold w-full lg:w-96 h-14" onClick={giveUserBalance}>
                    <p className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">Set Balance</p>
                </button>
            </div>
        </div>
    </div>
}

export default Admin;