import Bomb from "../../assets/bombs/exploded.png";
import Gold from "../../assets/coins/gold.png";

const classNames = require("classnames");

function Tile(props) {
  const mapping = {
    0: {
      1: 1.28,
      2: 1.64,
      3: 2.1,
      4: 2.68,
      5: 3.44,
      6: 4.4,
      7: 5.63,
      8: 7.21,
    },
    1: {
      1: 1.44,
      2: 2.07,
      3: 2.99,
      4: 4.3,
      5: 6.19,
      6: 8.92,
      7: 12.84,
      8: 18.49,
    },
    2: {
      1: 1.92,
      2: 3.69,
      3: 7.08,
      4: 13.59,
      5: 26.09,
      6: 50.1,
      7: 96.19,
      8: 184.68,
    },
    3: {
      1: 2.88,
      2: 8.29,
      3: 23.89,
      4: 68.8,
      5: 198.14,
      6: 570.63,
      7: 1643.42,
      8: 4733.04,
    },
    4: {
      1: 3.84,
      2: 14.75,
      3: 56.52,
      4: 217.43,
      5: 834.94,
      6: 3206.18,
      7: 12311.72,
      8: 47276.99,
    },
  };
  // console.log(props.id, props.revealed[parseInt(props.id.split("")[1]) - 1])
  return (
    <>
      {" "}
      {(props.revealed[parseInt(props.id.split("")[1]) - 1] || [])[
        parseInt(props.id.split("")[0])
      ] === undefined ? (
        <button
          onClick={() => {
            if (props.id.split("")[1] === props.row.toString()) {
              props.callback(props.id);
            }
          }}
          className={classNames(
            "font-bold rounded-md w-full flex-1 min-w-0 min-h-20 lg:min-h-0 h-20 p-3",
            props.id.split("")[1] === props.row.toString() || props.clicked
              ? "bg-primary-active text-white"
              : "bg-primary text-text-secondary"
          )}
        >
          {mapping[props.hard][props.id.split("")[1]].toFixed(2)}x
        </button>
      ) : (
        <div className="font-bold rounded-md w-full flex-1 min-w-0 min-h-20 lg:min-h-0 h-24 p-3 bg-primary/50 flex justify-center relative items-center">
          {(props.revealed[parseInt(props.id.split("")[1]) - 1] || [])[
            parseInt(props.id.split("")[0])
          ] === false ? (
            <img className="w-10 absolute" src={Gold} alt="Gold" />
          ) : (
            <img className="w-10 absolute" src={Bomb} alt="Bomb" />
          )}
        </div>
      )}
    </>
  );
}

export default Tile;
