import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
const betModify = new Audio(require("../../assets/audio/bet_modify.mp3"));

const MultiRange = ({ onRangeChange, children }) => {
  const initialValues = [10, 40, 60, 80]
  const [values, setValues] = useState(initialValues);
  const [winChance, setWinChance] = useState(50);
  const [firstRangeLowerBound, setFirstRangeLowerBound] = useState(10);
  const [firstRangeUpperBound, setFirstRangeUpperBound] = useState(40);
  const [secondRangeLowerBound, setSecondRangeLowerBound] = useState(60);
  const [secondRangeUpperBound, setSecondRangeUpperBound] = useState(80);
  const [mulitplier, setMultiplier] = useState(2.00);

  useEffect(() => {
    onRangeChange(values, mulitplier);
  });

  const handleSliderChange = (newValues) => {
    betModify.play()

    if (newValues[0] !== values[0]) {
      setFirstRangeLowerBound(newValues[0]);
    } else if (newValues[1] !== values[1]) {
      setFirstRangeUpperBound(newValues[1]);
    } else if (newValues[2] !== values[2]) {
      setSecondRangeLowerBound(newValues[2]);
    } else {
      setSecondRangeUpperBound(newValues[3]);
    }

    setValues(newValues)
    setWinChance((newValues[1] - newValues[0]) + (newValues[3] - newValues[2]));
    setMultiplier(calculateMuliplier((newValues[1] - newValues[0]) + (newValues[3] - newValues[2])));
    onRangeChange(newValues, mulitplier);
  };

  const calculateMuliplier = (winChance) => {
    return (100 / winChance).toFixed(2);
  }

  const handleFirstLowerBoundChange = (e) => {
    betModify.play()
    let valueInRange = e.target.value
    if (isNaN(valueInRange)) {
      valueInRange = 1;
    }

    if (valueInRange < 0) {
      valueInRange = 0;
    } else if (valueInRange > 99) {
      valueInRange = 99;
    }

    if (valueInRange > firstRangeUpperBound) {
      valueInRange = firstRangeUpperBound;
    }
    setFirstRangeLowerBound(valueInRange);
    setValues([valueInRange, firstRangeUpperBound, secondRangeLowerBound, secondRangeUpperBound]);
    setWinChance((firstRangeUpperBound - valueInRange) + (secondRangeUpperBound - secondRangeLowerBound));
    setMultiplier(calculateMuliplier((firstRangeUpperBound - valueInRange) + (secondRangeUpperBound - secondRangeLowerBound)));
    onRangeChange(valueInRange, mulitplier);
  }

  const handleFirstUpperBoundChange = (e) => {
    betModify.play()
    let valueInRange = e.target.value
    if (isNaN(valueInRange)) {
      valueInRange = 1;
    }

    if (valueInRange < 1) {
      valueInRange = 0;
    } else if (valueInRange > 100) {
      valueInRange = 100;
    }

    if (valueInRange < firstRangeLowerBound) {
      valueInRange = firstRangeLowerBound;
    } else if (valueInRange > secondRangeLowerBound) {
      valueInRange = secondRangeLowerBound;
    }
    setFirstRangeUpperBound(valueInRange);
    setValues([firstRangeLowerBound, valueInRange, secondRangeLowerBound, secondRangeUpperBound]);
    setWinChance((valueInRange - firstRangeUpperBound) + (secondRangeUpperBound - secondRangeLowerBound));
    setMultiplier(calculateMuliplier((valueInRange - firstRangeUpperBound) + (secondRangeUpperBound - secondRangeLowerBound)));
    onRangeChange(valueInRange, mulitplier);
  }

  const handleSecondLowerBoundChange = (e) => {
    betModify.play()
    let valueInRange = e.target.value
    if (isNaN(valueInRange)) {
      valueInRange = 1;
    }

    if (valueInRange < 1) {
      valueInRange = 0;
    } else if (valueInRange > 100) {
      valueInRange = 100;
    }

    if (valueInRange < firstRangeUpperBound) {
      valueInRange = firstRangeUpperBound;
    } else if (valueInRange > secondRangeUpperBound) {
      valueInRange = secondRangeUpperBound;
    }
    setSecondRangeLowerBound(valueInRange);
    setValues([firstRangeLowerBound, firstRangeUpperBound, valueInRange, secondRangeUpperBound]);
    setWinChance((firstRangeUpperBound - firstRangeLowerBound) + (secondRangeUpperBound - valueInRange));
    setMultiplier(calculateMuliplier((firstRangeUpperBound - firstRangeLowerBound) + (secondRangeUpperBound - valueInRange)));
    onRangeChange(valueInRange, mulitplier);
  }

  const handleSecondUpperBoundChange = (e) => {
    betModify.play()
    let valueInRange = e.target.value
    if (isNaN(valueInRange)) {
      valueInRange = 1;
    }

    if (valueInRange < 1) {
      valueInRange = 0;
    } else if (valueInRange > 100) {
      valueInRange = 100;
    }

    if (valueInRange < secondRangeLowerBound) {
      valueInRange = secondRangeLowerBound;
    }
    setSecondRangeUpperBound(valueInRange);
    setValues([firstRangeLowerBound, firstRangeUpperBound, secondRangeLowerBound, valueInRange]);
    setWinChance((firstRangeUpperBound - firstRangeLowerBound) + (valueInRange - secondRangeLowerBound));
    setMultiplier(calculateMuliplier((firstRangeUpperBound - firstRangeLowerBound) + (valueInRange - secondRangeLowerBound)));
    onRangeChange(valueInRange, mulitplier);
  }



  return (
    <div className="flex flex-col justify-center gap-5 w-full">
      <div className='w-full'>
        <div className="flex items-center justify-between text-center mb-1 relative w-full h-fit">
          {children}
          <p className='w-5'>0</p>
          <p className='w-5'>25</p>
          <p className='w-5'>50</p>
          <p className='w-5'>75</p>
          <p className='w-5'>100</p>
        </div>
        <div className="bg-primary p-2 rounded-full w-full">
          <Slider
            range
            min={0}
            max={100}
            value={values}
            onChange={handleSliderChange}
            allowCross={false}
            trackStyle={[{
              backgroundColor: '#64e300',
              borderColor: "#090C1D", height: 7,
            }, {
              backgroundColor: '#f11f5e',
              borderColor: "#090C1D",
              height: 7
            }, {
              backgroundColor: '#64e300',
              borderColor: "#090C1D", height: 7,
            }]}
            handleStyle={[{
              backgroundColor: '#2c2852',
              opacity: 1,
              borderColor: "#090C1D",
              borderWidth: 5,
              padding: 4,
              marginTop: -5,
            }, {
              backgroundColor: '#2c2852',
              opacity: 1,
              borderColor: "#090C1D",
              borderWidth: 5,
              padding: 4,
              marginTop: -5,
            }, {
              backgroundColor: '#2c2852',
              opacity: 1,
              borderColor: "#090C1D",
              borderWidth: 5,
              padding: 4,
              marginTop: -5,
            }, {
              backgroundColor: '#2c2852',
              opacity: 1,
              borderColor: "#090C1D",
              borderWidth: 5,
              padding: 4,
              marginTop: -5,
            }]}
            railStyle={{
              backgroundColor: '#f11f5e',
              borderColor: "#090C1D",
              height: 7
            }}
          /></div>
      </div>

      <div className="flex items-center gap-5 p-5 bg-primary rounded-md w-full">
        <div className="flex-1 min-w-0">
          <p className='text-lg font-bold'>Roll Over</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className='text-lg font-bold text-text-secondary'>&lt;</p>
            <input value={firstRangeLowerBound} onChange={handleFirstLowerBoundChange} min={0} max={100} type='number' className='w-full flex-1 h-12 bg-transparent outline-none' />
          </div>
        </div>

        <div className="flex-1 min-w-0">
          <p className='text-lg font-bold'>Roll Under</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className='text-lg font-bold text-text-secondary'>&lt;</p>
            <input value={firstRangeUpperBound} onChange={handleFirstUpperBoundChange} min={0} max={100} type='number' className='w-full flex-1 h-12 bg-transparent outline-none' />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-5 p-5 bg-primary rounded-md w-full">
        <div className="flex-1 min-w-0">
          <p className='text-lg font-bold'>Roll Under</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className='text-lg font-bold text-text-secondary'>&lt;</p>
            <input value={secondRangeLowerBound} onChange={handleSecondLowerBoundChange} min={0} max={100} type='number' className='w-full flex-1 h-12 bg-transparent outline-none' />
          </div>
        </div>
        <div className="flex-1 min-w-0">
          <p className='text-lg font-bold'>Roll Under</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className='text-lg font-bold text-text-secondary'>&lt;</p>
            <input value={secondRangeUpperBound} onChange={handleSecondUpperBoundChange} min={0} max={100} type='number' className='w-full flex-1 h-12 bg-transparent outline-none' />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-5 p-5 bg-primary rounded-md w-full">
        <div className="flex-1 min-w-0">
          <p className='text-lg font-bold'>Win Chance</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className='text-lg font-bold text-text-secondary'>%</p>
            <input readOnly={true} value={winChance} type='number' className='w-full flex-1 h-12 bg-transparent outline-none' />
          </div>
        </div>
        <div className="flex-1 min-w-0">
          <p className='text-lg font-bold'>Multiplier</p>
          <div className="flex items-center gap-2 bg-background rounded-lg pl-3 pr-1">
            <p className='text-lg font-bold text-text-secondary'>X</p>
            <input readOnly={true} value={mulitplier} type='number' className='w-full flex-1 h-12 bg-transparent outline-none' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiRange;
