import { useContext, useCallback } from "react";
import { UserContext } from "./index.js";
const { REACT_APP_BACKEND_URL } = process.env;

/**
 * Fetches data from the backend
 * 
 * @param {string} method
 * @param {string} path
 * @param {object} body
 * @returns {Promise<Response>}
 */
export function fetch_with_token(method, path, body) {
  return fetch(`${REACT_APP_BACKEND_URL}${path}`, {
    method: method,
    headers: {
      "x-access-token": localStorage.getItem("token") || "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

/**
 * Updates the balance of the user
 * @returns {function(): Promise<void>}
 */
export function useUpdateBalance() {
  const { user, setUser } = useContext(UserContext);

  const updateBalance = useCallback(async () => {
    const response = await fetch_with_token("GET", "/api/balance");
    const data = await response.json();

    if (user.balance !== data.balance)
      setUser((user) => ({ ...user, balance: data.balance }));
  }, [user, setUser]);
  
  return updateBalance;
}
